<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        class="pt-0"
      >
        <filters-side-sheet
          :expand-table-filters.sync="expandTableFilters"
          :loading-data="loadingData"
          :filters-are-active="filtersAreActive"
        >
          <template v-slot:title>
            {{ $t('schedules.filters') }}
          </template>
          <template v-slot:filters>
            <report-schedules-filters
              :loading-data="loadingData"
              :expand-table-filters.sync="expandTableFilters"
              :active-filters.sync="activeFilters"
            />
          </template>
        </filters-side-sheet>
        <table-toolbar
          :context="tableToolbarContext"
          :loading-data="loadingData"
          :expand-table-filters.sync="expandTableFilters"
          :filters-are-active="filtersAreActive"
          @refreshData="getTableData"
        />
        <card-layout
          title-class="caption"
          content-class="pt-0"
          :disable-title="!filtersAreActive"
        >
          <template v-slot:title>
            <filter-chips :active-filters="activeFilters">
              <template v-slot:label="{ item }">
                {{ $t('schedules.' + item) }}
              </template>
            </filter-chips>
          </template>
          <template v-slot:content>
            <v-data-table
              :loading="loadingData"
              :headers="headers"
              :items="tableData.items"
              :options.sync="paginationOptions"
              :server-items-length="tableData.itemCount"
              :style="tableWrapStyle"
              :footer-props="{
                itemsPerPageOptions: paginationOptions.itemsRange,
                showCurrentPage: true,
                showFirstLastPage: true,
                disablePagination: loadingData,
                disableItemsPerPage: loadingData
              }"
            >
              <template v-slot:item.frequency="{ item }">
                <span v-if="item.frequency">
                  {{ $t('schedules.' + item.frequency) }}
                </span>
              </template>
              <template v-slot:item.active="{ item }">
                <status-chip
                  small
                  :status="item.status"
                />
              </template>
              <template v-slot:item.createdAt="{ item }">
                {{ getDateTimeValue(item.createdAt, 'long') }}
              </template>
              <template v-slot:item.startsAt="{ item }">
                {{ getDateTimeValue(item.startsAt, 'long') }}
              </template>
              <template v-slot:item.lastExecutedAt="{ item }">
                {{ getDateTimeValue(item.lastExecutedAt, 'long') }}
              </template>
              <template v-slot:item.nextExecutionAt="{ item }">
                {{ getDateTimeValue(item.nextExecutionAt, 'long') }}
              </template>
              <template v-slot:item.updatedAt="{ item }">
                {{ getDateTimeValue(item.updatedAt, 'long') }}
              </template>
              <template v-slot:item.actions="{ item }">
                <table-button
                  :link="{
                    name: 'ReportSchedulesDetails',
                    params: {
                      routeDetailId: item.id
                    }
                  }"
                  text="general.details"
                />
              </template>
            </v-data-table>
          </template>
        </card-layout>
      </v-col>
    </v-row>
    <schedule-create-dialog
      @refresh="getTableData"
    />
  </v-container>
</template>

<script>
import api from '@/api/modules/schedules'
import { listViews } from '@/mixins/list_views'
import { getDateTimeValue } from '@/mixins/dates'
import CardLayout from '@/components/layout/CardLayout'
import StatusChip from '@/components/chips/StatusChip'
import FiltersSideSheet from '@/components/side_sheets/FiltersSideSheet'
import ReportSchedulesFilters from '@/components/filters/schedules/Filters'
import FilterChips from '@/components/chips/FilterChips'
import TableToolbar from '@/components/toolbars/TableToolbar'
import TableButton from '@/components/buttons/TableButton'
import ScheduleCreateDialog from '@/components/modals/schedules/Create'

export default {
  name: 'ReportSchedulesList',
  components: {
    CardLayout,
    StatusChip,
    FiltersSideSheet,
    ReportSchedulesFilters,
    FilterChips,
    TableToolbar,
    TableButton,
    ScheduleCreateDialog
  },
  mixins: [
    listViews,
    getDateTimeValue
  ],
  computed: {
    headers () {
      return [
        { text: this.$t('general.details'), sortable: false, value: 'actions', width: 48 },
        { text: this.$t('schedules.title'), sortable: false, value: 'title' },
        { text: this.$t('schedules.activeStatus'), sortable: false, value: 'active' },
        { text: this.$t('schedules.format'), sortable: false, value: 'format' },
        { text: this.$t('schedules.rrule'), sortable: false, value: 'frequency' },
        { text: this.$t('schedules.periodMonths'), sortable: false, value: 'periodMonths', align: 'right' },
        { text: this.$t('schedules.periodDays'), sortable: false, value: 'periodDays', align: 'right' },
        { text: this.$t('schedules.createdAt'), sortable: false, value: 'createdAt' },
        { text: this.$t('schedules.startsAt'), sortable: false, value: 'startsAt' },
        { text: this.$t('schedules.lastExecutedAt'), sortable: false, value: 'lastExecutedAt' },
        { text: this.$t('schedules.nextExecutionAt'), sortable: false, value: 'nextExecutionAt' },
        { text: this.$t('schedules.updatedAt'), sortable: false, value: 'updatedAt' }
      ]
    }
  },
  methods: {
    async getTableData () {
      const params = {
        ...this.paginationOptions,
        activeFilters: Object.assign({}, this.activeFilters)
      }
      this.loadingData = true
      const result = await api.fetchList(params)
      this.tableData = { ...result }
      this.loadingData = false
    }
  }
}
</script>
