<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
    >
      <date-picker
        :date-picker-props="createdDateFromProps"
        :data="form"
        :locale="locale"
        reference="createdDateFrom"
        @close="createdDateFromProps.menu = false"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <date-picker
        :date-picker-props="createdDateToProps"
        :data="form"
        :locale="locale"
        reference="createdDateTo"
        @close="createdDateToProps.menu = false"
      />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <custom-select
        v-model="form.activeStatus"
        hide-details
        :prepend-icon="$vuetify.icons.values.schedule"
        :items="activeStatuses"
        item-value="id"
        item-text="name"
        clearable
        :label="$t('schedules.activeStatus')"
      />
    </v-col>
  </v-row>
</template>

<script>
import {
  props,
  mounted,
  beforeDestroy,
  computedProperties,
  commonMethods
} from '@/mixins/filters'
import DatePicker from './picker/Date'
import CustomSelect from '@/components/inputs/CustomSelect'

export default {
  name: 'ReportSchedulesFilters',
  components: { DatePicker, CustomSelect },
  mixins: [
    props,
    mounted,
    beforeDestroy,
    computedProperties,
    commonMethods
  ],
  data () {
    return {
      form: {
        createdAtFrom: null,
        createdAtTo: null,
        activeStatus: null
      },
      createdDateFromProps: {
        menu: false,
        label: 'schedules.createdDateFrom'
      },
      createdDateToProps: {
        menu: false,
        label: 'schedules.createdDateTo'
      }
    }
  },
  computed: {
    activeStatuses () {
      return [
        { id: 'true', name: this.$t('general.active') },
        { id: 'false', name: this.$t('general.inactive') }
      ]
    }
  }
}
</script>
